<script setup lang="ts">
import { MeIcon, MeCheckbox, MeButton } from "@/libs/metha-components";
import { useAuthStore, useContractStore } from "@/stores";
import { handleError } from "@/utils/handleError";
import { ref } from "vue";
import { toast } from "vue3-toastify";

const contractStore = useContractStore();

const accepted = ref(false);

async function sendContractSignature() {
  if (!accepted.value) return;

  try {
    const authStore = useAuthStore();
    const token = await authStore.getToken("sendContractSignature");

    contractStore.sendContractSignature(token);
  } catch (error) {
    handleError(
      error,
      (errorMessage: string) => {
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      },
      {
        default:
          "Ocorreu um erro ao tentar registrar a assinatura do contrato. Tente novamente mais tarde por favor.",
      }
    );
  }
}
</script>

<template>
  <div class="contract-display">
    <div
      class="contract-display__close-button"
      @click="contractStore.closeContract"
    >
      <MeIcon>x</MeIcon>
    </div>
    <h2>Termos de Adesão e Comercialização</h2>
    <div class="contract-display__content">
      <button
        class="contract-display__content__download-terms"
        type="button"
        @click="contractStore.downloadTerms('adesao')"
      >
        <MeIcon>contract</MeIcon>
        <span class="contract-display__content__download-terms__title">
          Termo de Adesão
        </span>
      </button>
      <button
        class="contract-display__content__download-terms"
        type="button"
        @click="contractStore.downloadTerms('comercial')"
      >
        <MeIcon>contract</MeIcon>
        <span class="contract-display__content__download-terms__title">
          Termo de Comercialização
        </span>
      </button>
    </div>
    <MeCheckbox v-model="accepted" class="contract-display__accept-terms">
      <span class="contract-display__accept-terms__text">
        Declaro que li e concordo com os termos acima.
      </span>
    </MeCheckbox>
    <div class="contract-display__option-buttons">
      <p
        @click="contractStore.closeContract"
        class="contract-display__option-buttons__go-back link"
      >
        Voltar
      </p>
      <MeButton :disabled="!accepted" @click="sendContractSignature">
        Continuar
      </MeButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contract-display {
  padding: 2rem 1.5rem;
  text-align: center;

  &__close-button {
    font-size: 1.25rem;

    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    cursor: pointer;
  }

  &__content {
    margin: 1.5rem auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__download-terms {
      background: #fff;
      border: 1px solid #ced4da;
      border-radius: 1rem;
      padding: 1rem 1.5rem;
      width: 100%;

      display: flex;
      align-items: center;
      text-align: left;

      cursor: pointer;

      color: #000;
      font-size: 1.125rem;
      font-family: DM Sans;
      font-style: normal;
      font-weight: 700;
      line-height: 1.6875rem;
      text-decoration: none;

      &:hover {
        color: #2db699;
      }

      &__title {
        text-decoration: underline;
        margin-left: 0.25rem;
      }
    }
  }

  &__accept-terms {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 1rem;

    &__text {
      margin-left: 0.5rem;
      text-align: left;
    }
  }

  &__option-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    &__go-back {
      cursor: pointer;
      margin-right: 1rem;
    }
  }
}
</style>
