import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "past-injections-graph" }

import { reactive, onBeforeMount } from "vue";
import { MeColumnsChart } from "@/libs/metha-components";

import { orderByReferenceMonth } from "@/utils/orderInvoices";
import { Invoice } from "@/types/InvoiceTypes";


export default /*@__PURE__*/_defineComponent({
  __name: 'PastConsumptionGraph',
  props: {
    invoices: {},
    mainColor: {},
    secondaryColor: {}
  },
  setup(__props: any) {

const props = __props;

const chartInfo = reactive({
  labels: [] as string[],
  methaConsumptionValues: [] as number[],
  methaColor: props.mainColor ?? "#FFCC12",
  supplierConsumptionValues: [] as number[],
  supplierColor: props.secondaryColor ?? "#E3E5E4",
});

let lastInvoice: {
  kwh_injected: number;
  kwh_consumption: number;
  reference_month: string;
};

const months = [
  "JAN",
  "FEV",
  "MAR",
  "ABR",
  "MAI",
  "JUN",
  "JUL",
  "AGO",
  "SET",
  "OUT",
  "NOV",
  "DEZ",
];

function getNextMonth(month: string) {
  const monthIndex = months.findIndex((monthName) => month === monthName);

  if (monthIndex === months.length - 1) {
    return "JAN";
  }

  return months[monthIndex + 1];
}

onBeforeMount(() => {
  let orderedInvoices = orderByReferenceMonth(props.invoices).reverse();

  orderedInvoices
    .slice(0, 6)
    .forEach(
      (invoice: {
        kwh_injected: number;
        kwh_consumption: number;
        reference_month: string;
      }) => {
        const { kwh_injected, kwh_consumption, reference_month } = invoice;

        const [invoiceMonth] = reference_month.split("/");
        const supplierConsumption = kwh_consumption - kwh_injected;

        chartInfo.labels = [...new Set([...chartInfo.labels, invoiceMonth])];
        chartInfo.methaConsumptionValues = [
          ...chartInfo.methaConsumptionValues,
          kwh_injected,
        ];
        chartInfo.supplierConsumptionValues = [
          ...chartInfo.supplierConsumptionValues,
          supplierConsumption,
        ];

        lastInvoice = invoice;
      }
    );

  if (chartInfo.labels && chartInfo.labels.length < 6 && lastInvoice) {
    let securityLock = 0;

    while (chartInfo.labels.length < 6 && securityLock < 10) {
      const nextMonth = getNextMonth(
        chartInfo.labels[chartInfo.labels.length - 1]
      );

      chartInfo.labels = [...new Set([...chartInfo.labels, nextMonth])];

      securityLock += 1;
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "past-injections-graph__title" }, " Consumo em kWh dos últimos 6 meses ", -1)),
    _createVNode(_unref(MeColumnsChart), {
      labels: chartInfo.labels,
      primaryValues: chartInfo.methaConsumptionValues,
      primaryColor: chartInfo.methaColor,
      secondaryValues: chartInfo.supplierConsumptionValues,
      secondaryColor: chartInfo.supplierColor
    }, null, 8, ["labels", "primaryValues", "primaryColor", "secondaryValues", "secondaryColor"]),
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"past-injections-graph__legend\" data-v-131003be><p class=\"past-injections-graph__legend__item\" data-v-131003be><span class=\"past-injections-graph__legend__color-tag past-injections-graph__legend__color-tag--secondary\" data-v-131003be></span> energia concessionária </p><p class=\"past-injections-graph__legend__item\" data-v-131003be><span class=\"past-injections-graph__legend__color-tag past-injections-graph__legend__color-tag--primary\" data-v-131003be></span> energia limpa metha </p></div>", 1))
  ]))
}
}

})