<script setup lang="ts">
import { onMounted } from "vue";
import { MeApp } from "@/libs/metha-components";

import { useContractStore, useUserStore, useWarningStore } from "@/stores";

import WarningModal from "@/components/molecules/WarningModal.vue";
import MethaContract from "@/components/organisms/MethaContract.vue";

const userStore = useUserStore();
const contractStore = useContractStore();
const warningStore = useWarningStore();

onMounted(() => {
  contractStore.clear();
});
</script>

<template>
  <MeApp id="me-app">
    <slot></slot>
    <!-- Supplier Warning -> Refaturamento de Março/2024 -->
    <WarningModal
      v-if="warningStore.showSupplierWarning"
      title="Atenção!"
      text="Caso a sua <b>fatura com referência a Março/24</b> ainda não tenha sido disponibilizada, não se preocupe. Ela será enviada em breve! A concessionária realizou um refaturamento e por isso os dados foram disponibilizados de forma mais tardia."
      icon="alert"
      closeButtonText="Entendi"
      :closeModal="warningStore.closeSupplierWarning"
    />
    <!-- Busy Chat Warning -> Alta demanda dentro do chat -->
    <WarningModal
      v-if="warningStore.showBusyChatWarning && userStore.calledOpenChat"
      title="Atenção!"
      text="Em virtude das atualizações em nossas plataformas de atendimento/área do cliente para trazer mais qualidade, estamos enfrentando uma alta demanda, por isso o tempo de retorno pode ser maior do que o usual. A sua dúvida será respondida assim que possível! Agradecemos a sua compreensão."
      icon="alert"
      closeButtonText="Entendi"
      :closeModal="warningStore.closeBusyChatWarning"
    />
    <!-- Componente que renderiza o contrato via contractStore -->
    <MethaContract v-if="contractStore.open" />
  </MeApp>
</template>

<style lang="scss">
#me-app {
  background: #f6f6f6;

  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  @media (min-width: 993px) {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 258px auto;
    grid-template-areas:
      "sidebar header"
      "sidebar main";
  }
}

.main {
  width: 100%;
  grid-area: main;
  flex-grow: 1;

  @media (min-width: 993px) {
    background-color: transparent;
    max-width: calc(100vw - 258px);
    min-height: calc(100vh - 108px);
  }

  &__content {
    width: 100%;
    height: 100%;

    &--rounded {
      background-color: #fff;
      padding-top: 10px;
      border-radius: 36px 36px 0px 0px;

      @media (min-width: 993px) {
        padding-top: 0;
        background-color: #f6f6f6;
      }
    }
  }
}

.sidebar {
  width: 100%;
  grid-area: sidebar;
}

.header {
  width: 100%;
  grid-area: header;
  background-color: #f6f6f6;
}
</style>
