import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "installation-card" }


import { FlatInstallation } from "@/types/InstallationTypes";
import InstallationInfo from "@/components/molecules/InstallationInfo.vue";
// import SignContractActivator from "@/components/atoms/SignContractActivator.vue"

// const props = defineProps<{

export default /*@__PURE__*/_defineComponent({
  __name: 'InstallationCard',
  props: {
    installation: {}
  },
  setup(__props: any) {

// import { computed, defineProps } from "vue"


// const needContractUpdate = computed(() => {
//   return (
//     props.installation.status === "pending_agreement" ||
//     props.installation.pendency_contract_update
//   )
// })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstallationInfo, {
      status: _ctx.installation.status,
      installationNumber: _ctx.installation.installation_number,
      address: _ctx.installation.address,
      supplier: _ctx.installation.supplier
    }, null, 8, ["status", "installationNumber", "address", "supplier"])
  ]))
}
}

})