import request from "./request";
import { AxiosRequestConfig } from "axios";
import { Query } from "./types";
import { PfContractVariables, PjContractVariables } from "@/types/Contract";

export async function preRenderContract(
  data: PfContractVariables | PjContractVariables,
  headers = {},
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "PATCH",
    url: "/register/preRenderContract",
    data: { data },
    headers,
    params,
    ...config,
  });
}

export async function sendContractSignature(
  installation_id: string | number,
  headers = {},
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "PATCH",
    url: `client-area/update-contract-installation/${installation_id}`,
    headers,
    params,
    ...config,
  });
}
